<!-- Presupuestos sucursales de una Central -->

<template>
  <div class="funecM_Pres" v-if="loaded">
    <v-dialog v-model="modal" content-class="modal" persistent>

        <div>

          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader">
            </baseHeader>
          </div>

          <v-sheet :elevation="4">
            <div class="contenedor" style="width:900px">

              <!-- Filtro de Busqueda -->
              <div class="columna">
                <div style="display:flex">

                  <!-- x año -->
                  <v-select style="flex: 0 0 15%" v-bind="$select" v-model="ct.ano[2]" :items="itemsAno"
                      :label="ct.ano[1]" item-value="ano" item-text="label" @change="eventFiltro()">
                  </v-select>

                  <!-- x tipo de presupuesto -->
                  <v-select style="flex: 0 0 35%;margin-left: 5px;" v-bind="$select" v-model="ct.tipo[2]"
                    :items="filtroPresupuestos" label="Tipo presupuesto" item-value="id" item-text="label"
                    @change="eventFiltro()">
                  </v-select>

                  <div v-show="ct.ano[2]>= new Date().getFullYear()">
                    <fun_pres_util
                      style="flex: 0 0 20%"
                      :data_util="dataPresUtil">
                    </fun_pres_util>
                  </div>

                </div>
              </div>

              <!-- Grid -->
              <baseGridMD
                style="padding-top:10px"
                :store="storeName"
                :headers="headerGrid"
                :items-per-page="-1"
                dense>
              </baseGridMD>
            </div>
          </v-sheet>
        </div>
    </v-dialog>
  </div>
</template>



<script>

    import { mixF } from "@/mixins/mixF.js";
    import baseHeader from "@/base/baseHeader";
    import baseGridMD from "@/base/baseGridMD";
    import fun_pres_util from "@/components/fun_pres_util";


    export default {
      mixins: [mixF],
      components: { baseHeader, baseGridMD, fun_pres_util },
      props: {},

      data() {
        return {
          stIni: {
            api: "funecMPresF",
            titulo:"·Presupuestos sucursales",
            name:"funecMPresF",
            mView:'',
            pView:[],
          },

          filtroPresupuestos:[],
          itemsAno: null,
        };
      },


      methods: {
        iniDataParticular() {
          console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

          this.headers = [{
            name:[],
            fields:[
              { text: "Funeraria", value: "name", witdh: "45%", slot:true },
              { text: "Origen", value: "name_loc", witdh: "35%" },
              { text: "Situ", value: "situ", witdh: "10%" },
              { text: "ID", value: "id", witdh: "5%" },
              { text: "Pres", value: "npres", witdh: "5%" }
            ]}
          ];

          this.headerGrid= this.headerSet();

          // obtiene array de años
          this.itemsAno= this.getAnos();
          this.ct.ano[2]= this.itemsAno[2].ano;

          // obtengo items tipo presupuestos
          this.filtroPresupuestos = this.$store.state.datos_iniciales.filtroPresupuestos;

          // le indico que el finder tiene un autoload con la función 'buscar'
          // al cargar el componente hago un recordGet
          this.autoLoad= 'buscar';

        },


        // array de los últimos 4 años, incluido el actual
        getAnos() {
          const fechaActual = new Date();
          const anoActual = fechaActual.getFullYear();
          let ano= [];

          ano.push({ id: "", ano: "", label: "TODOS" });
          ano.push({ id: "0", ano: (anoActual + 1), label: (anoActual + 1) });
          for (let i = 0; i < 3; i++) {
            ano.push({ id:i+1, ano:(anoActual - i), label:(anoActual - i) });
          }

          return ano;
        },





      },


      computed: {

        dataPresUtil() {
          if (!Object.keys(this.masterSyncRecord).length) return {};
          const r= this.masterSyncRecord;

          let obj={};
          obj.ano= this.ct.ano[2];
          obj.funeraria= { central:true, id:r.id, name:r.name };
          obj.presupuesto= this.filtroPresupuestos.find(x => x.id == this.ct.tipo[2]);
          obj.cia = { id: 83, label: "MAPFRE" };
          obj.municipios = [];

          // Para indicar que es una central a fun_pres_util
          obj.central=true;
          return obj;
        }

      }

    };

</script>


