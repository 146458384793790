<!-- Mto particular de Funerarias Centrales -->

<template>
  <div class="funec-m" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
      persistent>

        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor" style="width:960px">

                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left">

                  <!-- Mto -->
                  <baseBtraMto class="conflex"
                    :perm="permMto"
                    :modulo="btMtoCfg"
                    :estado="estado"
                    @onEvent="execAccion">
                  </baseBtraMto>

                  <!-- Botones Copia y Pega .. controlar pega solo en alta y si ha copiado antes y copy en ver -->
                  <div class="conflex" style="margin-left: 5px">
                    <v-btn fab depressed x-small color="blue-grey lighten-4" class="pl-3" v-if="noEdit"
                        :disabled="!noEdit"
                        @click="copy()">copia
                    </v-btn>
                    <v-btn fab depressed x-small color="blue-grey lighten-4" class="pl-3" v-if="copiado"
                        :disabled="!(!noEdit && copiado)"
                        @click="pega()">pega
                    </v-btn>
                  </div>
                  <!-- --------------------------------------------------------- -->

                  <!-- Extra -->
                  <div class="conflex" style="margin-left: 50px">
                    <baseBtraExtra class="conflex"
                      :permExtra="permExtra"
                      :modulo="btExtCfg"
                      @onEvent="execAccion">
                    </baseBtraExtra>
                  </div>

                </div>

                <!-- Controles del Mto -->
                <div class="columna" style="padding-top:10px">
                  <div class="conflex">
                    <div class="columna" style="width:70%">
                      <div class="cab">DATOS GENERALES</div>
                      <v-sheet v-bind="$cfg.styles.marco">
                        <div class="conflex">
                          <uiText
                            v-model="ct.name[2]"
                            style="width:87%"
                            styleInput="font-weight:bold;"
                            uCase
                            :label="ct.name[1]"
                            :disabled="noEdit">
                          </uiText>
                          <uiText
                            style="width:13%"
                            v-model="ct.id[2]"
                            styleInput="font-size:12px;font-style:italic;"
                            :label="ct.id[1]"
                            disabled>
                          </uiText>
                        </div>

                        <uiText
                          v-model="ct.dire[2]"
                          :label="ct.dire[1]"
                          :disabled="noEdit">
                        </uiText>

                        <ctrlF
                          :ct="ct.loc"
                          :edicion="!noEdit">
                        </ctrlF>

                        <div class="conflex">
                          <uiText
                            style="width:50%"
                            v-model="ct.cp[2]"
                            :label="ct.cp[1]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            style="width:50%"
                            v-model="ct.cif[2]"
                            :label="ct.cif[1]"
                            :disabled="noEdit"
                            format="cif">
                          </uiText>
                        </div>
                      </v-sheet>
                    </div>

                    <div class="columna"  style="width:30%;padding-left:10px">
                      <div class="cab">CLASIFICACIÓN</div>
                      <v-sheet v-bind="$cfg.styles.marco">
                        <v-select
                          style="flex: 1 1 100%"
                          v-bind="$select"
                          :items="$store.state.datos_iniciales.situ"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="label"
                          v-model="ct.situ[2]"
                          :label="ct.situ[1]">
                        </v-select>

                        <v-select
                          style="flex: 1 1 100%"
                          v-bind="$select"
                          v-model="ct.tipo[2]"
                          :items="$store.state.datos_iniciales.tipo"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name"
                          :label="ct.tipo[1]">
                        </v-select>
                      </v-sheet>

                      <div class="cab">GRUPO</div>
                      <v-sheet width="100%" v-bind="$cfg.styles.marco">
                        <v-select
                          style="flex: 1 1 100%"
                          v-bind="$select"
                          v-model="ct.grup_id[2]"
                          :label="ct.grup_id[1]"
                          :items="itemsGrupo"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">
                        </v-select>
                      </v-sheet>
                    </div>
                  </div>

                  <div class="conflex" style="padding-top:10px">
                    <div class="columna" style="width:50%">
                      <div class="cab">DATOS FUNERARIA</div>
                      <v-sheet v-bind="$cfg.styles.marco">

                        <div class="columna" style="width:100%;padding-left:1px">
                          <div class="cab">EMPRESA</div>
                          <v-sheet v-bind="$cfg.styles.marco">
                            <uiText
                              :label="ct.razon[1]"
                              v-model="ct.razon[2]"
                              :disabled="noEdit">
                            </uiText>
                            <uiText
                              :label="ct.rep[1]"
                              v-model="ct.rep[2]"
                              :disabled="noEdit">
                            </uiText>
                            <div class="conflex">
                              <uiText
                                style="width:50%"
                                :label="ct.rep_nif[1]"
                                v-model="ct.rep_nif[2]"
                                :disabled="noEdit">
                              </uiText>
                              <uiText
                                style="width:50%"
                                :label="ct.rep_movil[1]"
                                v-model="ct.rep_movil[2]"
                                :disabled="noEdit">
                              </uiText>
                            </div>
                            <uiText
                              :label="ct.rep_email[1]"
                              v-model="ct.rep_email[2]"
                              :disabled="noEdit">
                            </uiText>
                          </v-sheet>
                        </div>

                        <div class="conflex">
                          <uiText
                            :label="ct.tlf1[1]"
                            v-model="ct.tlf1[2]"
                            :disabled="noEdit">
                          </uiText>
                          <uiText
                            :label="ct.tlf2[1]"
                            v-model="ct.tlf2[2]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            :label="ct.movil[1]"
                            v-model="ct.movil[2]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            :label="ct.fax1[1]"
                            v-model="ct.fax1[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <div class="conflex">
                          <uiText
                            style="width:100%"
                            :label="ct.cont1[1]"
                            v-model="ct.cont1[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>
                        <div class="conflex">
                          <uiText
                            style="width:100%"
                            :label="ct.cont2[1]"
                            v-model="ct.cont2[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <div class="conflex">
                          <uiText
                            :label="ct.email1[1]"
                            v-model="ct.email1[2]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            :label="ct.email2[1]"
                            v-model="ct.email2[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <uiUrl
                          v-model="ct.url[2]"
                          :label="ct.url[1]"
                          :disabled="noEdit">
                        </uiUrl>

                      </v-sheet>
                    </div>



                    <div class="columna" style="width:50%;padding-left:10px">
                      <div class="cab">ADMINISTRACIÓN</div>
                      <v-sheet v-bind="$cfg.styles.marco">
                        <uiText
                          :label="ct.admoncont[1]"
                          v-model="ct.admoncont[2]"
                          :disabled="noEdit">
                        </uiText>

                        <div class="conflex">
                          <uiText
                            style="flex: 1 1 20%"
                            :label="ct.admontlf1[1]"
                            v-model="ct.admontlf1[2]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            style="flex: 1 1 20%"
                            :label="ct.admonfax1[1]"
                            v-model="ct.admonfax1[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>
                        <div class="conflex">
                          <uiText
                            style="flex: 1 1 60%"
                            :label="ct.admonemail[1]"
                            v-model="ct.admonemail[2]"
                            :disabled="noEdit">
                          </uiText>
                          <uiText
                            style="flex: 1 1 60%"
                            :label="ct.admonemail2[1]"
                            v-model="ct.admonemail2[2]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <uiText
                          :label="ct.ban[1]"
                          v-model="ct.ban[2]"
                          :disabled="noEdit">
                        </uiText>

                        <div class="conflex">

                          <uiText
                            style="width:100%"
                            :label="ct.cta[1]"
                            v-model="ct.cta[2]"
                            format="banco"
                            :disabled="noEdit">
                          </uiText>

                          <!-- <v-btn
                            v-bind="$cfg.btra_cfg.standard"
                            title="Filtrar Facturas">
                              VTO
                          </v-btn> -->
                        </div>

                        <hr>

                        <div class="conflex">
                          <v-checkbox
                            style="width:40%"
                            v-bind="$checkbox"
                            v-model="ct.visarec[2]"
                            :label="ct.visarec[1]"
                            :disabled="noEdit">
                          </v-checkbox>

                          <uiText
                            style="width:40%"
                            :label="ct.sgl[1]"
                            v-model="ct.sgl[2]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            style="width:20%"
                            :label="ct.irpf[1]"
                            v-model="ct.irpf[2]"
                            format="money"
                            :ndecimals=1
                            :disabled="noEdit">
                          </uiText>
                        </div>

                      </v-sheet>

                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"
                        :edicion="!noEdit">
                      </uiObs3>

                    </div>

                  </div>
                </div>
              </div>
            </v-sheet>

            <!-- Ventana de Documentos -->
            <component
              :is="componenteDinamico"
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              tipoDocumento='5'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>

          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import commonDocsF from "@/components/commonDocsF.vue";
  import funecM_Pres from "@/components/funecM_Pres.vue";
  import ctrlF from "@/components/ctrlF";

  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, baseBtraExtra, dualTemplate, commonDocsF, ctrlF, funecM_Pres },
    props: {},

    data() {
      return {
        stIni: {
          api: "funecM",
          name:"funecM",
          titulo:"·Funerarias Centrales",
          recordAccess:"local",
          mView:'funecM', // Módulo manteniniento asociado
          pView:[]        // Permisos del mantenimiento asociado
        },

        itemsGrupo: [],

        ctSuc:["", "", "", [],
          {
            comp: {
              type:"pickV1",
              api:"generic",
              source:"funes",
              fAux:"name",
              valueAux:"",
              finder:"funesF",
              tipoView:"FA", // solo botón para ver el Mto
              mView:"",
              ctView:""
            }
          }
        ],

        copiado:false,
        pegado:false,

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'docs', btn: "docs" },
          { accion:'sucs', icono:"mdi-dots-vertical-circle-outline", title:"SUCURSALES" },
          { accion:'presup', icono:"mdi-list-box-outline", title:"PRESUPUESTOS" }

        ];

        //grupos añadir sin grupo
        this.itemsGrupo=this.$store.state.datos_iniciales.grupos.slice();
        this.itemsGrupo.unshift({id:'0',name:'* No Asignado'});

      },

      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.botonerasSet();
      },

      // desahbilitamos botones extra cuando se edita
      botonerasSet() {
        this.btnSet(this.btExtCfg, 'docs', { view: true, disabled:!this.noEdit? true : false });
        this.btnSet(this.btExtCfg, 'sucs', { view: true, disabled:!this.noEdit? true : false });
        this.btnSet(this.btExtCfg, 'presup', { view: true, disabled:!this.noEdit? true : false });
      },

      // copia datos (solo en ver)
      copy() {
        //const hijo = this.$store.state[this.stName];
        const record = JSON.parse(
          //JSON.stringify(this.$store.state[hijo].records)
          JSON.stringify(this.record)
        );
        if (record.length == 0) {
          this.$root.$alert.open("No hay datos para copiar", "error");
          return;
        }
        this.$store.commit("data2State", {
          modulo: "datos_iniciales",
          prop: "recordsCopy",
          value: record
        });
        this.$root.$alert.open("Datos Copiados", "info", 1500);

        this.copiado=true;
        this.pegado=false;
      },

      // pega datos si ha copiado antes y en nuevo ??
      async pega() {
        let record=this.$store.state.datos_iniciales.recordsCopy;
        if (!Object.keys(record).length) {
          this.$root.$alert.open("No hay datos para pegar", "error", 1800);
          return;
        }
        console.log('***pega', record);

        await this.record2ctrl(record, this.ct, false);
        // asignacion manual de comp observaciones
        this.ct.obs[4].comp.valueAux= record.obs;

        // preguntar si quiere traspasar los presupuestos - despues de grabar nuevo
        this.pegado=true;
        this.$root.$alert.open("Datos Pegados", "info", 1500);
      },

      async guardarFin() {
        //alert('guardado ... ' + this.estado);
        if (this.pegado && this.estado=='nuevo') {
          await this.añadir_aux_pregunta();
        }
        // quitamos estados copia pega
        this.copiado=this.pegado=false;
      },

      async añadir_aux_pregunta() {
        await this.$root.$alert.open("¿ AÑADIR SUCURSALES / DOCUMENTOS DESDE CENTRAL COPIADA ?","confirm",null,
          ["NO","SUCURSALES","DOCUMENTOS","SUCURSALES+DOCUMENTOS"])
          .then(r => {
            //alert(r);
            if (r) this.añadir_aux(r);
          });
      },

      // tip 1=suc,2=docs,3=pres+docs
      async añadir_aux(tip) {
        let aux1=this.$store.state.datos_iniciales.recordsCopy.id;
        let aux2=this.record.id;
        if (aux1 && aux1==aux2) return;

        // llamada API
        let param= { apiArg: ["", "cambioCentral", {}, "presupuesto"], args: { tip, aux1, aux2 } };
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        //this.$root.$alert.open("Sucursales/Documentos traspasados ...", "success", 1500);
      },

      // COMPONENTES DINAMICOS (asignación)

      // documentos de la funeraria
      docs() {
        this.componenteDinamico= 'commonDocsF';
      },

      // funerarias sucursales asociadas a la central
      sucs() {
        // guardo el nombre del store
        this.$root.$commonF.openFinder({
          ct:this.ctSuc,
          componenteName:this.ctSuc[4].comp.finder,
          accion:"buscarCentral",
          store:this.storeName
        });
      },

      // presupuestos funerarias sucursales
      presup() {
        this.componenteDinamico= 'funecM_Pres';
      }

    }
  }
</script>
